






















import Vue from "vue";
import { Component, PropSync } from "vue-property-decorator";

@Component
export default class ManagedToggle extends Vue {
  @PropSync("selected", { type: Number }) tab!: number;
  changeTab(idx: number) {
    this.tab = idx;
    this.$emit("change", idx);
  }
}
