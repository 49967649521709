













































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

// models
import { Session } from "@/core/models";

// Components
import {
  SessionsList,
  ManagedUsers,
  SessionFilters,
  SessionDetails,
  SessionsProgressPage,
} from "@/components/sessions";
import SessionsSelect from "./components/SessionsSelect.vue";
import SessionsToggle from "./components/SessionsToggle.vue";
import ProgressHeader from "./components/ProgressHeader.vue";
import ManagedToggle from "./components/ManagedToggle.vue";
import SessionLimitControl from "./components/SessionLimitControl.vue";

import { AudioPlayer, DisplayError } from "@/components/common";
import { SharingUser } from "@/core/store/managed";

@Component({
  components: {
    AudioPlayer,
    SessionsList,
    DisplayError,
    ManagedUsers,
    SessionFilters,
    SessionsSelect,
    SessionDetails,
    SessionsToggle,
    ProgressHeader,
    SessionLimitControl,
    SessionsProgressPage,
    ManagedToggle,
  },
})
export default class ManagedSessions extends Vue {
  @Getter("managed/active") active!: boolean;
  @Getter("managed/selected") selected!: string;
  @Getter("managed/sessions") sessions!: Session[];
  @Getter("managed/sessionsLoading") loading!: boolean;
  @Getter("managed/sharingUsers") users!: SharingUser[];
  @Getter("managed/selectedUser") selectedUser!: SharingUser;
  @Action("managed/setUser") setUser!: (s: string) => void;

  @Getter("currentSession/id") selectedSessionID!: number;
  @Getter("currentSession/session") currentSession!: Session;
  @Action("currentSession/setSession")
  setCurrentSession!: (s: Session | null) => void;

  selectedTab = 0;
  filteredSessions: Session[] = [];

  get selectedUserName() {
    if (this.selectedUser) return this.selectedUser.username;
    return this.$t("sess.nouser").toString();
  }

  changeTab(tab: number) {
    this.selectedTab = tab;
    if (tab === 0) this.setCurrentSession(this.filteredSessions[0]);
    else if (tab === 1) this.setCurrentSession(Session.Empty());
  }

  reset() {
    this.filteredSessions = this.sessions;
    if (this.sessions.length > 0) this.setCurrentSession(this.sessions[0]);
  }

  applyFilter(filtered: Session[]) {
    if (filtered.length === 0) this.setCurrentSession(null);
    else if (!filtered.some(x => x.ID === this.selectedSessionID))
      this.setCurrentSession(filtered[0]);
    this.filteredSessions = filtered;
  }

  @Watch("loading")
  sessonsLoaded() {
    if (!this.loading) this.reset();
  }

  @Watch("sessions", { immediate: true })
  sessionsChanged() {
    if (this.sessions.length == 0) this.setCurrentSession(Session.Empty());
    else {
      const session = this.sessions.find(x => x.ID === this.selectedSessionID);
      if (session) this.setCurrentSession(session);
      else this.setCurrentSession(this.sessions[0]);
    }
    this.filteredSessions = this.sessions;
  }

  mounted() {
    this.reset();
    const { email } = this.$route.params;
    if (email) this.setUser(email);
    else this.$router.push("/sessions/list");
  }
}
